.buttonGenerateBilling {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
}

.buttonGenerateBilling, 
.buttonGenerateBilling__icon,
.buttonGenerateBilling__text {
    transition: all 0.3s;
}

.buttonGenerateBilling .buttonGenerateBilling__text {
    display: flex;
    justify-content: center;
    /*  transform: translateX(30px);*/
    color: #fff;
    font-weight: 600;
}

.buttonGenerateBilling .buttonGenerateBilling__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonGenerateBilling .svgButtonGenerateBilling {
    width: 20px;
    fill: #fff;
}

.buttonGenerateBilling:hover {
    background: #34974d;
}

.buttonGenerateBilling:hover .buttonGenerateBilling__text {
    color: transparent;
}

.buttonGenerateBilling:hover .buttonGenerateBilling__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonGenerateBilling:active .buttonGenerateBilling__icon {
    background-color: #34974d;
}

.buttonGenerateBilling:active {
    border: 1px solid #34974d;
}

.buttonGenerateBilling__textSmall {
    display: none !important;
}
.containerButtonsBilling{
    display: flex;
}

@media screen and (max-width: 899px) {
    .buttonGenerateBilling {
        height: 45px;
        width: 45px;
        margin-bottom: 20px;
        border-radius: 50% !important;
        margin-right: 10px;
        font-size: 16px;
        position: fixed;
        right: 36px;
        top: 80vh;
        transition: all 300ms ease 0ms;
        box-shadow: 2px 3px 5px rgba(0, 0, 0);
        z-index: 99;
    }

    .buttonGenerateBilling__text {
        display: none !important;
    }

    .buttonGenerateBilling__textSmall {
        display: block !important;
    }
    .containerButtonsBilling {
        display: flex;
    }

}