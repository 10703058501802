.labelClient{
    font-size: clamp(20px, 3vw, 26px) !important;
    font-weight: 400 !important;
}
.montoPagar{
    font-size: clamp(20px, 3vw, 26px)  !important;
    font-weight: 400 !important;
}
.labelPay{
    font-size: clamp(18px, 3vw, 26px) !important;
    font-weight: 400 !important;
}
.labelTotal{
    font-size: clamp(18px, 3vw, 26px) !important;
    font-weight: 400 !important;
}
.sendBilling {
    z-index: 99999 !important;
}
.sendTitleBilling {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.my-swal {
    z-index: 99999 !important;
}
.my-Titleswal {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.formRegisterClient {
    z-index: 99999 !important;
}
.formTitleRegisterClient {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.formRegisterDetailProduct {
    z-index: 99999 !important;
}
.formTitleRegisterDetailProduct {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.formDeleteDetailProduct {
    z-index: 99999 !important;
}
.formTitleDeleteDetailProduct{
    font-family: Oswald !important;
    font-weight: 200 !important;
}

.formEditDetailProduct {
    z-index: 99999 !important; 
} 
.formTitleEditDetailProduct {
    font-family: Oswald !important;;
    font-weight: 200 !important;
}
.formRegisterDetailBilling {
    z-index: 99999 !important;
}
.formTitleRegisterDetailBilling{
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.formCAFC{
    z-index: 99999 !important;
}
.formTitleCAFC {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.validateBilling {
    z-index: 99999 !important;
}
.validateTitleBilling {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.notFoundNIT {
    z-index: 99999 !important;
}
.notTitleFoundNIT {
    font-family: Oswald !important; 
    font-weight: 200 !important;
}
@media screen and (max-width: 899px) {
    .titleSubtotal{
        display: flex !important;
        justify-content: flex-end !important;
    }
}


