.buttonCloseX {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b4b4b0;
    background-color: gray;
    overflow: hidden;
}

.buttonCloseX .buttonCloseX__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}


.buttonCloseX .buttonCloseX__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: #b4b4b0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonCloseX .svgCloseX {
    width: 20px;
    fill: #fff;
}
