.buttonLoading {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #94948f;
    background-color: #94948f;
    overflow: hidden;
}


.buttonLoading, 
.buttonLoading__icon,
.buttonLoading__text {
    transition: all 0.3s;
}


.buttonLoading .buttonLoading__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonLoading .buttonLoading__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: #94948f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonLoading .svgLoading {
    width: 20px;
    fill: #fff;
}
