.searchUser {
    background: url(https://cdn0.iconfinder.com/data/icons/slim-square-icons-basics/100/basics-19-32.png) no-repeat 0px 5px;
    background-size: 28px;
    width: 60%;
    height: 40px;
    border: transparent;
    border-bottom: solid 1px #ccc;
    padding: 10px 10px 10px 30px;
    outline: none;
    margin: 10px 0 20px 0;
    font-size: 14px;
}

@media screen and (max-width: 899px) {
    .searchUser {
        width: 100% !important;
        border-radius: 20px;
        border-bottom: solid 1px #e4e4e4;
    }
}