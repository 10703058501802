.buttonLoaderPhone {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e3e3;
    background-color: #e3e3e3;
    overflow: hidden;
}

.buttonLoaderPhone,
.buttonLoaderPhone__icon,
.buttonLoaderPhone__text {
    transition: all 0.3s;
}

.buttonLoaderPhone .buttonLoaderPhone__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonLoaderPhone .buttonLoaderPhone__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonLoaderPhone .svgSendLoader {
    width: 20px;
    fill: #fff;
}

.buttonLoaderPhone:hover {
    background: #e3e3e3;
}

.buttonLoaderPhone:hover .buttonLoaderPhone__text {
    color: transparent;
}

.buttonLoaderPhone:hover .buttonLoaderPhone__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonLoaderPhone:active .buttonLoaderPhone__icon {
    background-color: #e3e3e3;
}

.buttonLoaderPhone:active {
    border: 1px solid #e3e3e3;
}

.buttonLoaderPhone__textSmall{
    display: none !important;
}

@media screen and (max-width: 899px) {
    .buttonLoaderPhone{
        height: 45px !important; 
        width: 45px !important;
        margin: 0px !important;
        border-radius: 50% !important;
        font-size: 16px;
        position: fixed;
        right: 36px;
        top: 86vh;
        transition: all 300ms ease 0ms;
        box-shadow: 2px 3px 5px rgba(0, 0, 0);
        z-index: 99;
    }
    .buttonLoaderPhone__text{
        display: none !important;
    }
    .buttonLoaderPhone__textSmall{
        display: block !important;
    }
}
