.buttonClose {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b4b4b0;
    background-color: #b4b4b0;
    overflow: hidden;
}

.buttonClose,
.buttonClose__icon,
.buttonClose__text {
    transition: all 0.3s;
}


.buttonClose .buttonClose__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonClose .buttonClose__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #b4b4b0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonClose .svgClose {
    width: 20px;
    fill: #fff;
}

.buttonClose:hover {
    background: #b4b4b0;
}

.buttonClose:hover .buttonClose__text {
    color: transparent;
}

.buttonClose:hover .buttonClose__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonClose:active .buttonClose__icon {
    background-color: #b4b4b0;
}

.buttonClose:active {
    border: 1px solid #b4b4b0;
}

.buttonClose__textSmall {
    display: none !important;
}

@media screen and (max-width: 899px) {
    .buttonClose {
        height: 45px;
        width: 45px; 
        border-radius: 50% !important;
        font-size: 16px;
        position: fixed;
        right: 36px;
        top: 86vh;
        transition: all 300ms ease 0ms;
        box-shadow: 2px 3px 5px rgba(0, 0, 0);
        z-index: 99;
    }

    .buttonClose__text {
        display: none !important;
    }

    .buttonClose__textSmall {
        display: block !important;
    }
}