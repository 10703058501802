.labelFormClient {
    font-family:  Oswald !important;;
    font-weight: 400 !important;
    color: gray !important;
}

.buttonRegister {
    position: relative;
    top: 20px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
}

.buttonRegister,
.buttonRegister__icon,
.buttonRegister__text {
    transition: all 0.3s;
}

.buttonRegister .buttonRegister__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonRegister .buttonRegister__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRegister .svgRegister {
    width: 20px;
    fill: #fff;
}

.buttonRegister:hover {
    background: #34974d;
}

.buttonRegister:hover .buttonRegister__text {
    color: transparent;
}

.buttonRegister:hover .buttonRegister__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonRegister:active .buttonRegister__icon {
    background-color: #34974d;
}

.buttonRegister:active {
    border: 1px solid #34974d;
}

.formRegisterClient{
    z-index: 99999 !important;
}
.formTitleRegisterClient{
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.formRegisterProduct {
    z-index: 9999999 !important;
}

.formTitleRegisterProduct {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.buttonRegister__textSmall{
    display: none !important;
}

@media screen and (max-width: 899px) {
    .buttonRegister{
        height: 45px; 
        width: 45px;
        margin-bottom: 20px; 
        border-radius: 50% !important;
        font-size: 16px;
        position: fixed;
        right: 36px;
        top: 86vh;
        transition: all 300ms ease 0ms;
        box-shadow: 2px 3px 5px rgba(0, 0, 0);
        z-index: 99;
    }
    .buttonRegister__text{
        display: none !important;
    }
    .buttonRegister__textSmall{
        display: block !important;
    }
}