.cardSwitch p {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #666;
    height: 46px;
}

.cardSwitch p.smallSwitch {
    font-size: 14px;
}

.go-cornerSwitch {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #FDAFAB;
    border-radius: 0 4px 0 32px;
}

.go-arrowSwitch {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
}

.cardSwitch1 {
    display: block;
    position: relative;
    max-width: 262px;
    background-color: rgb(248, 241, 241);
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
}

.cardSwitch1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #FDAFAB;;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.cardSwitch1:hover:before {
    transform: scale(21);
}

.cardSwitch1:hover p {
    transition: all 0.3s ease-out;
    color: black;
}

.cardSwitch1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}

.cardSwitch1:hover .iconSwitch {
    transition: all 0.3s ease-out;
    color: black;
}

.cardSwitch2 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
}

.cardSwitch2:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #ccc;
    background-color: white;
}

.cardSwitch2:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: chocolate;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
}

.cardSwitch2:hover:before {
    transform: scale(2.15);
}

.cardSwitch3 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #f2f8f9;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #f2f8f9;
}

.cardSwitch3 .go-cornerSwitch {
    opacity: 0.7;
}

.cardSwitch3:hover {
    border: 1px solid chocolate;
    box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    z-index: 500;
}

.cardSwitch3:hover p {
    color: chocolate;
}

.cardSwitch3:hover .go-cornerSwitch {
    transition: opactiy 0.3s linear;
    opacity: 1;
}

.cardSwitch4 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    background-color: #fff;
    border-radius: 4px;
    padding: 32px 24px;
    margin: 12px;
    text-decoration: none;
    overflow: hidden;
    border: 1px solid #ccc;
}

.cardSwitch4 .go-cornerSwitch {
    background-color: chocolate;
    height: 100%;
    width: 16px;
    padding-right: 9px;
    border-radius: 0;
    transform: skew(6deg);
    margin-right: -36px;
    align-items: start;
    background-image: linear-gradient(-45deg, #8f479a 1%, #dc2a74 100%);
}

.cardSwitch4 .go-arrowSwitch {
    transform: skew(-6deg);
    margin-left: -2px;
    margin-top: 9px;
    opacity: 0;
}

.cardSwitch4:hover {
    border: 1px solid chocolate;
}

.cardSwitch4 h3 {
    margin-top: 8px;
}

.cardSwitch4:hover .go-cornerSwitch {
    margin-right: -12px;
}

.cardSwitch4:hover .go-arrowSwitch {
    opacity: 1;
}