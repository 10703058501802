.buttonRegisterData {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
    color: white;
    font-weight: 400;
}

.buttonRegisterData,
.buttonRegisterData__icon,
.buttonRegisterData__text {
    transition: all 0.3s;
}

.buttonRegisterData .buttonRegisterData__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonRegisterData .buttonRegisterData__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRegisterData .svgRegisterData {
    width: 20px;
    fill: #fff;
}

.buttonRegisterData:hover {
    background: #34974d;
}

.buttonRegisterData:hover .buttonRegisterData__text {
    color: transparent;
}

.buttonRegisterData:hover .buttonRegisterData__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonRegisterData:active .buttonRegisterData__icon {
    background-color: #34974d;
}

.buttonRegisterData:active {
    border: 1px solid #34974d;
}

.titleProduct{
    font-size: clamp(1.5rem, 2.5vw, 28px)!important;
}