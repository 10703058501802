@media screen and (max-width: 800px) {
    .panelLogo{
        display: none !important;
    }
    .panelFormLogin{
        height: 100vh !important;
        width: 100vw !important;
    }
    .containerFormLogin{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 90vh !important;
        width: 80vw !important;
        row-gap: 50px;
    }
    .logoIcon{
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        width: 50vw !important;
        height: 50vw !important;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .labelSignIn{
        font-size: 9vw !important;
    }
    .labelEmail{
        margin-top: 20px !important;
        font-size: 5vw !important;
    }
    .labelPassword{
        font-size: 5vw !important;
    }
    .labelForgetPassword{
        font-size: 4vw !important;
    }
    .textFieldPassword{
        font-size: 42px !important;
        width: 100% !important;
        color: red;
    }
    .textFieldEmail{
        font-size: 42px !important;
        width: 100% !important;
    }
    .labelErrorPassword{
        font-size: 3.8vw !important;
        margin-bottom: 10px !important;
    }
    .labelErrorEmail{
        font-size: 3.8vw !important;
        margin-bottom: 10px !important;
    }
    .buttonSubmit {
        margin-top: 5px;
        height: 6.5vh;
        width: 15rem;
    }
    .labelButton {
        font-size: 4.2vw !important;
    }
}
