.buttonRegisterBranch {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
    color: white;
    font-weight: 400;
}

.buttonRegisterBranch,
.buttonRegisterBranch__icon,
.buttonRegisterBranch__text {
    transition: all 0.3s;
}

.buttonRegisterBranch .buttonRegisterBranch__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonRegisterBranch .buttonRegisterBranch__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRegisterBranch .svgRegisterBranch {
    width: 20px;
    fill: #fff;
}

.buttonRegisterBranch:hover {
    background: #34974d;
}

.buttonRegisterBranch:hover .buttonRegisterBranch__text {
    color: transparent;
}

.buttonRegisterBranch:hover .buttonRegisterBranch__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonRegisterBranch:active .buttonRegisterBranch__icon {
    background-color: #34974d;
}

.buttonRegisterBranch:active {
    border: 1px solid #34974d;
}







.formRegisterBranch {
    z-index: 99999 !important;
}
.formTitleRegisterBranch{
    font-family: Oswald !important;
    font-weight: 200 !important;
}