.labelFormEditClient{
    font-family: Oswald !important;
    font-weight: 400 !important;
    color: gray !important;
}
.buttonEdit {
    position: relative;
    width: 100%;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D1B000;
    background-color: #D1B000;
    overflow: hidden;
}

.buttonEdit,
.buttonEdit__icon,
.buttonEdit__text {
    transition: all 0.3s;
}

.buttonEdit .buttonEdit__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonEdit .buttonEdit__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #D1B000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonEdit .svgEdit {
    width: 20px;
    fill: #fff;
}

.buttonEdit:hover {
    background: #D1B000;
}

.buttonEdit:hover .buttonEdit__text {
    color: transparent;
}

.buttonEdit:hover .buttonEdit__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonEdit:active .buttonEdit__icon {
    background-color: #D1B000;
}

.buttonEdit:active {
    border: 1px solid #D1B000;
}

.formEditClient{
    z-index: 99999 !important;
}
.formEditTitleClient{
    font-family: Oswald !important;
    font-weight: 200 !important;
}
