@keyframes homeMessage {
    0% 
    {
        opacity: 0;
    }
    
    100% 
    {
        opacity: 1;
    }
}

.homeMessage {
    animation: homeMessage 5s ease;
}


