.buttonRCBilling {
    position: relative;
    top: 20px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
}

.buttonRCBilling,
.buttonRCBilling__icon,
.buttonRCBilling__text {
    transition: all 0.3s;
}

.buttonRCBilling .buttonRCBilling__text {
    display: flex;
    justify-content: center;
    /*  transform: translateX(30px);*/
    color: #fff;
    font-weight: 600;
}

.buttonRCBilling .buttonRCBilling__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRCBilling .svgRCBilling {
    width: 20px;
    fill: #fff;
}

.buttonRCBilling:hover {
    background: #34974d;
}

.buttonRCBilling:hover .buttonRCBilling__text {
    color: transparent;
}

.buttonRCBilling:hover .buttonRCBilling__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonRCBilling:active .buttonRCBilling__icon {
    background-color: #34974d;
}

.buttonRCBilling:active {
    border: 1px solid #34974d;
}

.buttonRCBilling__textSmall {
    display: none !important;
}
