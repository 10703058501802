.buttonAdmin {
    position: relative;
    top: 20px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #94948f;
    background-color: #94948f;
    overflow: hidden;
    color: white;
    font-weight: 400;
}

.buttonAdmin,
.buttonAdmin__icon,
.buttonAdmin__text {
    transition: all 0.3s;
}

.buttonAdmin .buttonAdmin__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonAdmin .buttonAdmin__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #94948f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonAdmin .svgAdmin {
    width: 20px;
    fill: #fff;
}

.buttonAdmin:hover {
    background: #94948f;
}

.buttonAdmin:hover .buttonAdmin__text {
    color: transparent;
}

.buttonAdmin:hover .buttonAdmin__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonAdmin:active .buttonAdmin__icon {
    background-color: #94948f;
}

.buttonAdmin:active {
    border: 1px solid #94948f;
}

.buttonAdmin__textSmall {
    display: none !important;
}

@media screen and (max-width: 899px) {
    .buttonAdmin {
        height: 45px;
        width: 45px;
        margin-bottom: 20px;
        border-radius: 50% !important;
        font-size: 16px;
        position: fixed;
        right: 96px;
        top: 86vh;
        transition: all 300ms ease 0ms;
        box-shadow: 2px 3px 5px rgba(0, 0, 0);
        z-index: 99;
    }

    .buttonAdmin__text {
        display: none !important;
    }

    .buttonAdmin__textSmall {
        display: block !important;
    }
}