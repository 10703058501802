.codigoCUFC {
    z-index: 99999 !important;
}

.codigoTitlteCUFC {
    font-family: Oswald !important;
    font-weight: 200 !important;
}
@media screen and (max-width: 800px) {
    .panelLogoPov{
        display: none !important;
    }
    .panelFormPov{
        height: 100vh !important;
        width: 100vw !important;
    }
    .containerFormPov{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90vh !important;
        width: 80vw !important;
        row-gap: 50px;
    }
    .logoIconPov{
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        width: 30vw !important;
        height: 30vw !important;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .labelPointOfSale{
        font-size: 9vw !important;
        color: white !important;
    }
    .labelErrorPov{
        font-size: 18px !important;
        margin-bottom: 10px !important;
    }
    .buttonCancelPov {
        margin-top: 5px;
        height: 6.5vh;
        width: 15rem;
    }
    .iconStore{
        width: 22vw !important;
        height: 22vw !important;
    }
    .buttonSelectPov{
        background-color: black; 
        margin-top: 5px;
        height: 6.5vh;
        width: 15rem;
    }
    .labelButtonPov{
        color: white !important;
    }
}
.codigoCUFC{
    z-index: 99999 !important;
    font-family: Oswald;
    font-weight: 200;
}