.my-custom-react-select{
    z-index: 10 !important;
}
.containerSearch{
    width: 90%;
}
.containerSucursal{
    margin-right: 20px;
}

@media screen and (max-width: 899px) {
    .titleBilling{
        margin-bottom: 20px;
    }
    .containerSearch{
        width: 100%;
    }
    .containerSucursal{
        margin-right: 0px;
    }

}
