.buttonEnable {
    position: relative; 
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #94948f;
    background-color: #94948f;
    overflow: hidden;
}

.buttonEnable,
.buttonEnable__icon,
.buttonEnable__text {
    transition: all 0.3s;
}

.buttonEnable .buttonEnable__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonEnable .buttonEnable__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #94948f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonEnable .svgEnable {
    width: 20px;
    fill: #fff;
}

.formEnableClient{
    z-index: 99999 !important;
}
.formTitleEnableClient{
    font-family: Oswald !important;
    font-weight: 200 !important;
}

.buttonEnable__textSmall {
    display: none !important;
}

@media screen and (max-width: 899px) {
    .buttonEnable {
        height: 45px;
        width: 45px;
        margin-bottom: 20px;
        border-radius: 50% !important;
        margin-right: 10px;
        font-size: 16px;
        position: fixed;
        right: 36px;
        top: 80vh;
        transition: all 300ms ease 0ms;
        box-shadow: 2px 3px 5px rgba(0, 0, 0);
        z-index: 99;
    }

    .buttonEnable__text {
        display: none !important;
    }

    .buttonEnable__textSmall {
        display: block !important;
    }
}