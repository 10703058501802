.resetPassword {
    z-index: 99999 !important;
}

.titleResetPassword {
    font-family: Oswald !important;
    font-weight: 200 !important;
}

@media screen and (max-width: 800px) {
    .panelLogoReset{
        display: none !important;
    }
    .panelFormReset{
        height: 100vh !important;
        width: 100vw !important;
    }
    .containerFormReset{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 90vh !important;
        width: 80vw !important;
        row-gap: 50px;
    }
    .logoIconReset{
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        width: 30vw !important;
        height: 30vw !important;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .iconReset{
        width: 22vw !important;
        height: 22vw !important;
    }
    .labelResetPassword{
        font-size: 9vw !important;
    }
    .labelPassword{
        margin-top: 20px !important;
        font-size: 5vw !important;
    }
    .labelComfirmPassword{
        font-size: 5vw !important;
    }
    .LabelErrorPassord{
        font-size: 3.8vw !important;
        margin-bottom: 10px !important;
    }
    .LabelErrorResetPassord{
        font-size: 3.8vw !important;
        margin-bottom: 10px !important;
    }
    .buttonResetPassword{
        margin-top: 5px;
        height: 6.5vh;
        width: 15rem;
        background-color: black;
    }
    .labelButtonReset{
        color: white !important;
        font-size: 4.2vw !important;
    }
}