.buttonLoaderTable {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e3e3;
    background-color: #e3e3e3;
    overflow: hidden;
}

.buttonLoaderTable,
.buttonLoaderTable__icon,
.buttonLoaderTable__text {
    transition: all 0.3s;
}

.buttonLoaderTable .buttonLoaderTable__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonLoaderTable .buttonLoaderTable__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonLoaderTable .svgSendLoader {
    width: 20px;
    fill: #fff;
}

.buttonLoaderTable:hover {
    background: #e3e3e3;
}

.buttonLoaderTable:hover .buttonLoaderTable__text {
    color: transparent;
}

.buttonLoaderTable:hover .buttonLoaderTable__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonLoaderTable:active .buttonLoaderTable__icon {
    background-color: #e3e3e3;
}

.buttonLoaderTable:active {
    border: 1px solid #e3e3e3;
}

.buttonLoaderTable__textSmall{
    display: none !important;
}
