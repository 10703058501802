.button {
    position: relative;
    width: 100%;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cc0000;
    background-color: #cc0000;
    overflow: hidden;
}

.button,
.button__icon,
.button__text {
    transition: all 0.3s;
}

.button .button__text {
    display: flex;
    justify-content: center;
  /*  transform: translateX(30px);*/
    color: #fff;
    font-weight: 600;
}

.button .button__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #cc0000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button .svg {
    width: 20px;
    fill: #fff;
}

.button:hover {
    background: #cc0000;
}

.button:hover .button__text {
    color: transparent;
}

.button:hover .button__icon {
    width: 100%;
    transform: translateX(0);
}

.button:active .button__icon {
    background-color: #cc0000;
}

.button:active {
    border: 1px solid #cc0000;
}

.formDisableClient {
    z-index: 99999 !important;
}

.formTitleDisableClient{
    font-family: Oswald !important;
    font-weight: 200 !important;
}        