.buttonSendData {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
}

.buttonSendData,
.buttonSendData__icon,
.buttonSendData__text {
    transition: all 0.3s;
}

.buttonSendData .buttonSendData__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonSendData .buttonSendData__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonSendData .svgSendData {
    width: 20px;
    fill: #fff;
}

.buttonSendData:hover {
    background: #34974d;
}

.buttonSendData:hover .buttonSendData__text {
    color: transparent;
}

.buttonSendData:hover .buttonSendData__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonSendData:active .buttonSendData__icon {
    background-color: #34974d;
}

.buttonSendData:active {
    border: 1px solid #34974d;
}