.buttonRDBilling {
    position: relative;
    top: 20px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
}

.buttonRDBilling,
.buttonRDBilling__icon,
.buttonRDBilling__text {
    transition: all 0.3s;
}

.buttonRDBilling .buttonRDBilling__text {
    display: flex;
    justify-content: center;
    /*  transform: translateX(30px);*/
    color: #fff;
    font-weight: 600;
}

.buttonRDBilling .buttonRDBilling__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRDBilling .svgRDBilling {
    width: 20px;
    fill: #fff;
}

.buttonRDBilling:hover {
    background: #34974d;
}

.buttonRDBilling:hover .buttonRDBilling__text {
    color: transparent;
}

.buttonRDBilling:hover .buttonRDBilling__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonRDBilling:active .buttonRDBilling__icon {
    background-color: #34974d;
}

.buttonRDBilling:active {
    border: 1px solid #34974d;
}

.buttonRDBilling__textSmall {
    display: none !important;
}
