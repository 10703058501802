.syncUp{
    z-index: 99999 !important;

}
.syncUpTitle{
    font-family: Oswald !important;
    font-weight: 200 !important;
}
.selectSync{
    width: 100% !important;
}
.labelSync{
    font-family: Oswald;
    font-size: clamp(20px, 3vw, 26px) !important;
    font-weight: 400 !important;
}


@media screen and (max-width: 899px) {
    .containerLabelSync {
        display: flex;
    }
}