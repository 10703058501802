.forgotPassword {
    z-index: 99999 !important;
}

.titleforgotPassword {
    font-family: Oswald !important;
    font-weight: 200 !important;
}

@media screen and (max-width: 800px) {
    .panelLogoFP {
        display: none !important;
    }
    .panelFormFP{
        height: 100vh !important;
        width: 100vw !important;
    }
    .containerFormFP{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 90vh !important;
        width: 80vw !important;
        row-gap: 50px !important;;
    }
    .logoIconFP{
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        width: 30vw !important;
        height: 30vw !important;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .iconMail{
        width: 22vw !important;
        height: 22vw !important;
    }
    .labelForgotPassword{
        font-size: 8vw !important;
        margin-bottom: 50px !important;
    }
    .labelEmailFP{
        font-size: 5vw !important;
    }
    .labelGoBackHome{
        font-size: 4vw !important;
    }
    .labelErrorEmail{
        font-size: 3.8vw !important;
        margin-bottom: 10px !important;
    }
    .iconEmail{
        font-size: 32px !important;
    }
    .textFieldEmailFP{
        width: 100% !important;
    }
    .buttonFP{
        margin-top: 5px;
        height: 6.5vh;
        width: 15rem;
    }
    .labelButtonFP{
        font-size: 4.2vw !important;
    }
}


