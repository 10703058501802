.buttonRegisterPov {
    position: relative;
    width: 100px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #34974d;
    overflow: hidden;
}

.buttonRegisterPov,
.buttonRegisterPov__icon,
.buttonRegisterPov__text {
    transition: all 0.3s;
}

.buttonRegisterPov .buttonRegisterPov__text {
    display: flex;
    justify-content: center;
    /*  transform: translateX(30px);*/
    color: #fff;
    font-weight: 600;
}

.buttonRegisterPov .buttonRegisterPov__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRegisterPov .svgRegisterPov {
    width: 20px;
    fill: #fff;
}

.buttonRegisterPov:hover {
    background: #34974d;
}

.buttonRegisterPov:hover .buttonRegisterPov__text {
    color: transparent;
}

.buttonRegisterPov:hover .buttonRegisterPov__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonRegisterPov:active .buttonRegisterPov__icon {
    background-color: #34974d;
}

.buttonRegisterPov:active {
    border: 1px solid #34974d;
}