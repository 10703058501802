.selectSucursal{
    margin-left: 20px !important;
    margin-right: 20px !important;
}

@media screen and (max-width: 899px) {
    .selectSucursal{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}