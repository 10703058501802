.inActive{
    text-decoration: none !important;
    color: gray !important;
    border: none !important;
}
.active{
    text-decoration: none !important;
    background-color: #eaeaec !important;
    width: 100% !important;
    height: 50px !important;
    color: goldenrod !important;
    font-weight: 800 !important;
}