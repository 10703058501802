.buttonUploadLogotipo{
    background-color: black;
    color: white;
}

@media screen and (max-width: 899px) {
    .buttonUploadLogotipo{
        width: 100% !important;
        margin-left: 0px !important;
        margin-bottom: 20px !important;
    }
}