.buttonStatistics {
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /*#34974d*/
    border: 1px solid black;
    background-color: black;
    overflow: hidden;
}

.buttonStatistics,
.buttonStatistics__icon,
.buttonStatistics__text {
    transition: all 0.3s;
}

.buttonStatistics .buttonStatistics__text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.buttonStatistics .buttonStatistics__icon {
    position: absolute;
    transform: translateX(109px);
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonStatistics .svgStatistics {
    width: 20px;
    fill: #fff;
}

.buttonStatistics:hover {
    background: black;
}

.buttonStatistics:hover .buttonStatistics__text {
    color: transparent;
}

.buttonStatistics:hover .buttonStatistics__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonStatistics:active .buttonStatistics__icon {
    background-color: black;
}

.buttonStatistics:active {
    border: 1px solid black;
}
