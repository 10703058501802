.activeUser{
    z-index: 99999 !important;
}
.titleActiveUser{
    font-family: Oswald !important;
    font-weight: 200 !important;
}

@media screen and (max-width: 800px) {
    .panelLogoActiveUser{
        display: none !important;
    }
    .panelFormActive{
        height: 100vh !important;
        width: 100vw !important;
    }
    .containerFormActive{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        height: 90vh !important;
        width: 80vw !important;
        row-gap: 50px !important;
    }
    .logoIconActive{
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        width: 30vw !important;
        height: 30vw !important;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .iconVPN{
        width: 22vw !important;
        height: 22vw !important;
    }
    .labelActiveUser{
        font-size: 9vw !important;
    }
    .labelPassword{
        margin-top: 20px !important;
        font-size: 5vw !important;
    }
    .labelComfirmAP{
        font-size: 5vw !important;
    }
    .labelErrorPassword{
        font-size: 3.8vw !important;
        margin-bottom: 10px !important;
    }
    .labelErrorComfirm{
        font-size: 3.8vw !important;
        margin-bottom: 10px !important;
    }
    .buttomActiveUser{
        margin-top: 20px;
        height: 60px;
        background-color: black;
    }
    .labelButtomActive{
        color: white !important;
        font-size: 4.2vw !important;
    }
}