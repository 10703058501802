.buttonGoBack {
    position: relative;
    top: 20px;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b4b4b0;
    background-color: gray;
    overflow: hidden;
}

.buttonGoBack,
.buttonGoBack__icon,
.buttonGoBack__text {
    transition: all 0.3s;
}

.buttonGoBack .buttonGoBack__text {
    display: flex;
    justify-content: center;
    /*  transform: translateX(30px);*/
    color: #fff;
    font-weight: 600;
}

.buttonGoBack .buttonGoBack__icon {
    position: absolute;
    transform: translateX(109px);

    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonGoBack .svgGoBack {
    width: 20px;
    fill: #fff;
}

.buttonGoBack:hover {
    background: gray;
}

.buttonGoBack:hover .buttonGoBack__text {
    color: transparent;
}

.buttonGoBack:hover .buttonGoBack__icon {
    width: 100%;
    transform: translateX(0);
}

.buttonGoBack:active .buttonGoBack__icon {
    background-color: gray;
}

.buttonGoBack:active {
    border: 1px solid #b4b4b0;
}

.buttonGoBack__textSmall {
    display: none !important;
}

@media screen and (max-width: 899px) {
    .buttonGoBack {
        height: 45px;
        width: 45px;
        margin-bottom: 20px;
        border-radius: 50% !important;
        font-size: 16px;
         position: fixed;
        right: 36px;
        top: 79vh; 
        transition: all 300ms ease 0ms;
        box-shadow: 2px 3px 5px rgba(0, 0, 0);
        z-index: 99;
    }

    .buttonGoBack__text {
        display: none !important;
    }

    .buttonGoBack__textSmall {
        display: block !important;
    }
}